<template>
  <div class="px-10 pt-12">
    <h2 class="mb-10">Whats your email?</h2>
    <ValidationObserver ref="emailForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" method="post">
        <ValidationProvider mode="eager" rules="required|email|max:255" vid="email" name="Email" v-slot="{ errors }">
          <input 
            v-model="form.email"
            type="email" 
            class="form-input"
            :class="errors.length ? 'border border-red-600' : ''"
            placeholder="yourmail@mail.com"
          />
          <span class="w-full text-red-600 flex justify-center pt-2"> {{ errors[0] }} </span>
        </ValidationProvider>
        <!-- Divider-->
        <!-- <div class="flex justify-center items-center w-full my-6 hlg:my-10">
          <span class="w-2/5 border-t border-gray-500 block h-1"></span>
          <span class="uppercase w-2/5 text-gray-500 block text-center">Or</span>
          <span class="w-2/5 border-t border-gray-500 block h-1"></span>
        </div> -->

        <!-- <div class="grid grid-cols-1 row-gap-5 hlg:row-gap-8">
          <button class="form-input flex items-center">
            <FacebookIcon class="mr-3"/>
            <span class="text-gray-500">Login with Facebook </span>
          </button>
          <button class="form-input flex items-center">
            <GoogleIcon class="mr-3"/>
            <span class="text-gray-500">Login with Google </span>
          </button>
          <button class="form-input flex items-center">
            <AppleIcon class="mr-3"/>
            <span class="text-gray-500">Login with Apple </span>
          </button>
        </div> -->

        <!--Button-->
        <div class="absolute px-10 w-full left-0 right-0 bottom-0">
          <button
            class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import { SET_FORM } from '@/store/types/mutations';

export default {
  name: "SignUpEmail",

  components: {
    // AppleIcon: () => import("@/components/svg/AppleIcon"),
    // GoogleIcon: () => import("@/components/svg/GoogleIcon"),
    // FacebookIcon: () => import("@/components/svg/FacebookIcon")
  },

  data: () => ({
    form: {
      email: ''
    }
  }),

  methods: {
    ...mapMutations({
      setForm: `auth/${SET_FORM}`
    }),

    submit() {
      this.setForm(this.form);

      this.$router.push({ name:'signUpPassword' });
    }
  }
}
</script>

<style scoped>

</style>
